<template>
  <div class="submenu flex-row justify-center">
    <a-layout class="level2Main flex-row justify-center">
      <div class="Menu flex-row justify-center">
        <!-- 左侧菜单栏 -->
        <div>
          <a-layout-sider class="leftMenu">
            <a-menu mode="inline" v-model:selectedKeys="selectedKeys">
              <a-menu-item
                v-for="item in menu"
                @click="item.id === 2 ? change(item) : ''"
                :key="item.id"
              >
                <router-link replace :to="item.path">{{
                  item.label
                }}</router-link>
              </a-menu-item>
            </a-menu>
          </a-layout-sider>
        </div>

        <!-- 中部 -->
        <a-layout class="view">
          <!-- 页面 -->
          <router-view />
        </a-layout>
      </div>
    </a-layout>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  data () {
    return {
      menu: [
        {
          id: 1,
          path: '/UserManagement/PersonInformation',
          label: '个人信息',
        }, {
          id: 2,
          path: '/UserManagement/MyQuestions/WorkOrder',
          label: '我的提问',
        }, {
          id: 3,
          path: '/UserManagement/MyEnterprise',
          label: '我的企业',
        },
      ],
      selectedKeys: [], // 当前选中的页面
    }
  },
  created () {
    const path = this.$route.path
    this.selectKey(path)
  },
  watch: {
    // $route (to) {
    //   this.selectKey(to.path)
    // },
  },
  methods: {
    change (item) {
      if (item.id === 2) {
        item.path = '/UserManagement/MyQuestions/WorkOrder'
        this.selectKey(item.path)
      }
    },
    selectKey (path) {
      const item = this.menu.find(function (item) {
        if (path === '/UserManagement/MyQuestions/Feedback') {
          if (item.id === 2) {
            item.path = path
          }
        }
        if (item.id === 3) {
          item.path = path
        }
        return item.path === path
      })
      if (item) {
        const selectedKeys = []
        selectedKeys.push(item.id)
        this.selectedKeys = selectedKeys
      }
    },
  },
})

</script>

<style lang="scss" scoped>
@import "@/assets/common.scss";
.submenu {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  margin: 0 auto;
}
.level2Main {
  margin-top: 24px;
  width: 82%;
  // background-color: pink;
}
.Menu {
  width: 82%;
}
.leftMenu {
  width: 14%;
  min-height: 500px;
  background: #fff;
  position: fixed;
}
::v-deep(.ant-menu-item) {
  margin-top: 0;
}
::v-deep(.ant-layout-sider-children) {
  min-height: 500px;
}
.view {
  margin-left: 210px;
  width: 80%;
  // margin-left: 211px;
}
</style>
